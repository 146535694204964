import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import {
  colors,
  spacer,
  breakpoints,
  misc,
} from '@dqp/common/styles/utilities'
import { LinkBtn } from '@dqp/common/components/Button/Button'
import Text from '@dqp/common/components/Text/Text'
import breakPoints from '@dqp/common/styles/helpers/breakpoints'
import FractionInCircle from '#components/FractionInCircle/FractionInCircle'
import ProgressBar from '#components/ProgressBar/ProgressBar'

const styles = {
  container: css`
    padding: 20px 0;
    border-radius: ${misc.borderRadius.md};
    box-shadow: ${misc.boxShadow.default};
    background-color: ${colors.white};
    ${breakPoints({
      paddingLeft: [10, 10, 30, 30, 30],
      paddingRight: [10, 10, 30, 30, 30],
    })}
  `,
  spacer: css`
    width: 25px;
  `,
  fractionInCircle: css`
    ${breakpoints({
      marginBottom: [20, 0, 0],
    })}
    flex-shrink: 0;
  `,
  button: css`
    white-space: pre-wrap;
    flex-shrink: 0;
    ${breakpoints({
      marginTop: [20, 10, 10],
    })}
  `,
}
function SubjectPerformance(props) {
  const {
    subjectName,
    correctAnswers,
    percentageCompleted,
    answeredQuestions,
    productColor,
    to,
  } = props
  const percentage = percentageCompleted
  return (
    <div
      css={styles.container}
      className='d-block d-sm-flex align-items-sm-center'
    >
      <FractionInCircle
        numerator={correctAnswers}
        denominator={answeredQuestions}
        bgColor={productColor}
        css={styles.fractionInCircle}
      />
      <div css={styles.spacer} />
      <div className='flex-grow-1'>
        <div
          className='d-flex align-items-center justify-content-between'
          css={spacer.mrB5}
        >
          <Text color={colors.black} weight='bold' size='medium'>
            {subjectName.toUpperCase()}
          </Text>
          <Text color={colors.black} weight='bold' size='medium'>
            {percentage}%
          </Text>
        </div>

        <ProgressBar barColor={productColor} progress={percentage} />

        <LinkBtn
          variant='black'
          size='small'
          css={styles.button}
          to={to}
          inline
        >
          PRACTICE {subjectName.toUpperCase()}
        </LinkBtn>
      </div>
    </div>
  )
}

SubjectPerformance.propTypes = {
  to: PropTypes.string.isRequired,
  productColor: PropTypes.string.isRequired,
  subjectName: PropTypes.string.isRequired,
  answeredQuestions: PropTypes.number.isRequired,
  percentageCompleted: PropTypes.number.isRequired,
  correctAnswers: PropTypes.number.isRequired,
}
export default SubjectPerformance
