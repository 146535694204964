import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import { colors } from '@dqp/common/styles/utilities'
import Text from '@dqp/common/components/Text/Text'

const styles = {
  container: ({ bgColor }) => css`
    background-color: ${bgColor};
    padding: 5px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    color: ${colors.white};
    justify-content: center;
    align-items: center;
  `,
}

function FractionInCircle({
  numerator,
  denominator,
  bgColor,
  className,
}) {
  return (
    <div css={styles.container({ bgColor })} className={className}>
      <Text
        as='span'
        color={colors.white}
        weight='bold'
        size='medium'
        css={css`
          padding-bottom: 2px;
        `}
      >
        {numerator}
      </Text>
      <Text
        as='span'
        color={colors.white}
        weight='bold'
        size='medium'
        css={css`
          border-top: 2px solid white;
        `}
      >
        {denominator}
      </Text>
    </div>
  )
}

FractionInCircle.propTypes = {
  numerator: PropTypes.number.isRequired,
  denominator: PropTypes.number.isRequired,
  bgColor: PropTypes.string.isRequired,
  className: PropTypes.string,
}

FractionInCircle.defaultProps = {
  className: '',
}
export default FractionInCircle
