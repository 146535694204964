import React, { useEffect } from 'react'
import { spacer } from '@dqp/common/styles/utilities'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'
import SubjectPerformanceStats from './SubjectPerformanceStats'
import OverallStats from '#containers/SubjectDashboard/OverallStats'
import Header from '#containers/SubjectDashboard/Header'
import { useGetProfile } from '#api/getProfile'
import Loader from '#components/Loader/Loader'
import Error from '#components/Error/Error'
import routes from '#globals/routes'
import { useGetSubscriptionOverview } from '#api/getSubscriptionOverview'
import { useThemeColor } from '#context/ThemeColorContext'

const defaultTab = { value: ``, label: `Overview` }

function OverviewDashboard({ profileId }) {
  const { setThemeColor } = useThemeColor()
  const { profile, loading, error } = useGetProfile({ profileId })
  const {
    subscriptionOverview,
    loading: subscriptionOverviewLoading,
    error: subscriptionOverviewError,
  } = useGetSubscriptionOverview({
    profileId,
  })

  useEffect(() => {
    setThemeColor(null)
  }, [setThemeColor])

  if (loading || subscriptionOverviewLoading) {
    return <Loader />
  }
  if (error || subscriptionOverviewError) {
    return <Error error={JSON.stringify(error)}>{error}</Error>
  }

  const { subscriptions } = profile.getProfile
  const activeSubscriptions = subscriptions.filter(
    subscription => subscription.is_active,
  )
  // if only one subscriptions navigate to first subject's dashboard
  if (activeSubscriptions.length === 1) {
    navigate(
      routes.subjectDashboard.getPathFromParams({
        profileId,
        subscriptionId: activeSubscriptions[0].id,
      }),
    )
    return null
  }

  const tabs = activeSubscriptions.map(subscription => ({
    value: subscription.id,
    label: subscription.product.label,
  }))

  const onTabClick = tab => {
    if (tab.value) {
      navigate(
        routes.subjectDashboard.getPathFromParams({
          profileId,
          subscriptionId: tab.value,
        }),
      )
    } else {
      navigate(
        routes.overviewDashboard.getPathFromParams({
          profileId,
        }),
      )
    }
  }

  return (
    <>
      <Header
        onTabClick={onTabClick}
        profileName={profile.getProfile.name}
        tabs={[defaultTab, ...tabs]}
        initialSelectedTab={[defaultTab, ...tabs].find(
          tab => tab.value === '',
        )}
      />
      <OverallStats
        correctAnswers={
          subscriptionOverview.getSubscriptionsOverview.good_answers
        }
        incorrectAnswers={
          subscriptionOverview.getSubscriptionsOverview.bad_answers
        }
        totalQuestions={
          subscriptionOverview.getSubscriptionsOverview
            .total_product_questions
        }
        completedQuestions={
          subscriptionOverview.getSubscriptionsOverview
            .answered_questions
        }
      />
      <div css={spacer.mrB40} />
      <SubjectPerformanceStats
        subjectsData={
          subscriptionOverview.getSubscriptionsOverview
            .subscriptions_performance
        }
        profileId={profileId}
      />
    </>
  )
}

OverviewDashboard.propTypes = {
  profileId: PropTypes.string.isRequired,
}

export default OverviewDashboard
