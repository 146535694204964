import React from 'react'
import PropTypes from 'prop-types'
import Text from '@dqp/common/components/Text/Text'
import { colors, spacer } from '@dqp/common/styles/utilities'
import SubjectPerformance from '#containers/SubjectDashboard/SubjectPerformance/SubjectPerformance'
import routes from '#globals/routes'

function SubjectPerformanceStats({ subjectsData, profileId }) {
  if (subjectsData.length === 0) return null
  return (
    <div className='container-md'>
      <Text
        size='xLarge'
        weight='bold'
        color={colors.black}
        css={spacer.mrB20}
      >
        Subject performance
      </Text>
      <div className='row'>
        {subjectsData.map(data => (
          <div className='col-lg-12' css={spacer.mrB20} key={data.id}>
            <SubjectPerformance
              {...data}
              to={routes.questionDashboard.getPathFromParams({
                profileId,
                subscriptionId: data.id,
              })}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

SubjectPerformanceStats.propTypes = {
  subjectsData: PropTypes.array.isRequired,
  profileId: PropTypes.string.isRequired,
}

export default SubjectPerformanceStats
